import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostOnePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const overview_img = data.overview_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Overview Of Dental Implants- The Dentists At Gateway Crossing</title>
      <meta name="description" content="Dental implants may be necessary if you have lost one or more teeth due to injury, disease, or tooth decay. Read more for an overview of dental implants." />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Dental implants may be necessary if you have lost one or more teeth due to injury, disease, or tooth decay. Read more for an overview of dental implants." />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Dental implants may be necessary if you have lost one or more teeth due to injury, disease, or tooth decay. Read more for an overview of dental implants." />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Dental Implants
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2021/07/06
                </li>
                <li className="list-inline-item badge badge-primary">
                  Dental Implants
                </li>
                <li className="list-inline-item badge badge-secondary">
                  Overview of dental implants
                </li>
              </ul>
                <Img fluid={overview_img} className="blog-post-img"/>
              <h2 className="mb-3">
                Overview of dental implants.
              </h2>
              <p>
                Dental implants are biocompatible structures that a dentist inserts into the jawbone to act as an artificial root. Dental implants may be necessary if you have lost one or more teeth due to injury, disease, or tooth decay. Dental implants come in many different types and each type has its own risks and benefits associated with it.
              </p>
              <p>
                Dental Implants are considered a safe method of tooth restoration when performed by a dentist or oral surgeon. Dental implant surgery is a common surgery in the United States and is growing in popularity. It is seen by most dentists and patients as the most effective and permanent solution for replacing missing teeth, either individually, or in groups of teeth.
              </p>
              <h3 className="mb-3">
                Learn more about dental implants below - including benefits and drawbacks!
              </h3>
              <p>
                Dental Implants can replace one or more teeth and help maintain the natural look of your smile. The dentist inserts an implant into the jawbone and it acts as a foundation for artificial teeth called crowns. The artificial tooth (or crown) connects to the dental implant through a device called an abutment.
              </p>
              <p>
                Dentists make crowns by replicating the natural teeth in a person's mouth and matching their color. Dental implants can also be used to support bridges, dentures, and partials.
              </p>
              <p>
                Implants offer several advantages over traditional dentures when replacing entire sets of teeth. Here are some of the advantages they offer.
              </p>
              <h4>
                Dental Implants:
              </h4>
              <ul className="blog-list">
                <li>
                  look and feel more natural
                </li>
                <li>
                  are more comfortable
                </li>
                <li>
                  improve your ability to chew and eat a wider variety of foods
                </li>
                <li>
                  decrease the risk of cavities in nearby teeth
                </li>
                <li>
                  tend to prevent bone loss in the jaw at the site where they are placed
                </li>
                <li>
                  usually decrease sensitivity in nearby teeth
                </li>
                <li>
                  are a permanent solution and do not need to be taken out
                </li>
              </ul>
              <p>
                Unfortunately, dental implants are not for everyone. Depending on your jawbone and other various factors, you may not be eligible for dental implants. People with illnesses such as HIV, diabetes, osteoporosis, and other ailments present higher risks that may make them ineligible for dental implant surgery. Those undergoing radiation or chemotherapy may also be ineligible.
              </p>
              <p>
                Smoking and using tobacco products will also often make you ineligible for dental implant surgery. Another reason to quit using them for good.
              </p>
              <p>
                There are other risks, complications, and variables that may arise or make the procedure more intensive for some than others as well. Your dentist will be able to examine you and tell you if you are a good candidate.
              </p>
              <p>
                As with any surgery, there is a possibility of complications. These complications can include infection, nerve damage, gum damage, and more. It should be noted that the chance of these complications is low, especially if you are an ideal candidate.
              </p>
              <p>
                Dental implant surgery is often a process over several months, as once the implant is placed, it will need to fuse with your jaw and take some time to heal.
              </p>
              <p>
                The biggest objection that people usually have regarding dental implants is the cost. It is not a cheap procedure. The cost can vary depending on the number of implants being placed and the prosthesis which is placed. Most insurances do not cover the cost of dental implants, so it is often an out of pocket expense. Though, some dentists will work with you on a payment plan.
              </p>
              <p>
                In spite of the cost, they are still a highly sought after procedure for replacing missing teeth. They are an investment in oneself and can have a profound impact on quality of life as well as the self-confidence of those who get them.
              </p>
              <p>
                Dental implants are considered to be capable of lasting your lifetime if they are cared for properly. Are you missing teeth? Regain your confidence with dental implants!
              </p>
              <p>
                Ask your dentist if dental implants are right for you. Schedule an appointment today!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogPostOnePage

export const query = graphql`
  query BlogPostOnePageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    overview_img: file(name: { eq: "overview-blog2" }) {
      childImageSharp {
        fluid(maxWidth: 1130, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
